import {
  getApiUrl,
  getBuildabilityAppUrl,
  getPlannerAppUrl,
  getSFIntegrationUrl,
} from './utils/environment';

interface Config {
  apiUrl: string;
  authentication: Authentication;
  sfIntegrationUrl: string;
  plannerUrl: string;
  buildabilityUrl: string;
  apiRCUrl: string;
  googleMapsAPIKey: string;
}

interface Authentication {
  clientId: string;
  tenantId: string;
  scopes: string[];
}

const config: Config = {
  /// TODO: need to change when the new domains is available for rest of the env
  apiUrl: document.location.origin.includes('.enpal.io')
    ? `https://fulfilment-api${getApiUrl()}.craftos.enpal.io/api/v1.0`
    : `https://fulfilment-api${getApiUrl()}.craftos.enpal.tech/api/v1.0`,
  apiRCUrl: `https://craftreasoncodeapi${getApiUrl()}.azurewebsites.net/api`,
  sfIntegrationUrl: getSFIntegrationUrl(),
  plannerUrl: getPlannerAppUrl(),
  buildabilityUrl: getBuildabilityAppUrl(),
  googleMapsAPIKey: 'AIzaSyA1iBH7daoW6fdbrely5sTSX6qkIjJ9pq8', //prod
  //googleMapsAPIKey: 'AIzaSyB6CWGBA60jLfACZhqA1Kb53MPed5FMqVc', // my local
  authentication: {
    clientId: '42eab713-24c8-45ab-95d6-cfdaa8d8d6e0',
    tenantId: 'd22da9a6-d89a-4764-9d56-96b020869dff',
    scopes: [
      'openid',
      'profile',
      'offline_access',
      'https://enpalcraftos.onmicrosoft.com/api/craftos/Forms.Read',
      'https://enpalcraftos.onmicrosoft.com/api/craftos/foo',
    ],
  },
};

export { config };
