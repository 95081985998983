import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  Space,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styles from '../progress.module.scss';
import { AddFilterIcon } from '../../../components/icons';
import { areEqualObjects } from '../../../utils/helpers';

interface Props {
  page: string;
  loading: boolean;
  handleChangeUrl: (arg0: any) => void;
}
interface CheckboxOption {
  label: string;
  value: LabelType;
  disabled: boolean;
  checked: boolean;
  visible: boolean;
}
enum LabelType {
  week = 'week',
  branch = 'branch',
  plId = 'plId',
  team = 'team',
  filter = 'filter',
  AppointmentTypes = 'AppointmentTypes',
}

type MenuItem = { key: string; label: string; disabled?: boolean };
const { Text } = Typography;

export const SavedFiltersButton: React.FC<Props> = ({
  page,
  loading,
  handleChangeUrl,
}) => {
  let location = useLocation();
  const intl = useIntl();
  const [form] = Form.useForm();

  const [savedFilterObject, setSavedFilterObject] = useState<any>({});
  const [filtersDropdownMenuList, setFiltersDropdownMenuList] = useState<
    MenuItem[]
  >([
    {
      key: '0',
      label: intl.formatMessage({ id: 'no_saved_filters' }),
      disabled: true,
    },
  ]);

  const [optionsWithDisabled, setOptionsWithDisabled] = useState<
    CheckboxOption[]
  >([]);
  const [open, setOpen] = useState(false);
  const [activeFilterKeys, setActiveFilterKeys] = useState<string[]>([]);

  const handleReformatFilterValue = (value: any | string) => {
    if (typeof value === 'string') {
      // string to obj:
      let newObject: any = {};
      let newList = value.split('&').filter((item) => !!item);
      newList.forEach(
        (item: string) => (newObject[item.split('=')[0]] = item.split('=')[1])
      );
      return newObject;
    } else {
      //obj to string:
      let newString = '';
      Object.keys(value).forEach((key: any) => {
        if (!!value[key])
          newString = newString.concat(key, '=', value[key], '&');
      });
      return newString.slice(0, -1);
    }
  };

  const getSavedFilters = () => {
    let savedFiltersString = localStorage.getItem(`${page}-json-filters`);
    if (!!savedFiltersString) {
      setSavedFilterObject(JSON.parse(savedFiltersString));
      if (savedFiltersString !== '{}') {
        setFiltersDropdownMenuList(
          Object.keys(JSON.parse(savedFiltersString)).map((key: string) => {
            return {
              key: key,
              label: key,
            };
          })
        );
      } else {
        setFiltersDropdownMenuList([
          {
            key: '0',
            label: intl.formatMessage({ id: 'no_saved_filters' }),
            disabled: true,
          },
        ]);
      }
    }
  };

  const handleParsingFilterParameters = (currentFilter: any) => {
    let sortedOptionsWithDisabled = [];
    if (
      typeof currentFilter === 'string'
        ? currentFilter.includes('week=')
        : currentFilter?.week
    ) {
      sortedOptionsWithDisabled.push({
        label: intl.formatMessage({ id: 'week' }),
        value: LabelType.week,
        disabled: false,
        checked: false,
        visible: true,
      });
    }
    if (
      typeof currentFilter === 'string'
        ? currentFilter.includes('AppointmentTypes=')
        : currentFilter?.AppointmentTypes
    ) {
      sortedOptionsWithDisabled.push({
        label: intl.formatMessage({ id: 'appointment_type_filter' }),
        value: LabelType.AppointmentTypes,
        disabled: false,
        checked: true,
        visible: true,
      });
    }
    if (
      typeof currentFilter === 'string'
        ? currentFilter.includes('branch=')
        : currentFilter?.branch
    ) {
      sortedOptionsWithDisabled.push({
        label: intl.formatMessage({ id: 'branch' }),
        value: LabelType.branch,
        disabled: false,
        checked: true,
        visible: true,
      });
    }
    if (
      typeof currentFilter === 'string'
        ? currentFilter.includes('team=')
        : currentFilter?.team
    ) {
      sortedOptionsWithDisabled.push({
        label: intl.formatMessage({ id: 'team_selection' }),
        value: LabelType.team,
        disabled: false,
        checked: true,
        visible: true,
      });
    }
    if (
      typeof currentFilter === 'string'
        ? currentFilter.includes('plId=')
        : currentFilter?.plId
    ) {
      sortedOptionsWithDisabled.push({
        label: intl.formatMessage({ id: 'project_leads' }),
        value: LabelType.plId,
        disabled: false,
        checked: true,
        visible: true,
      });
    }
    if (
      typeof currentFilter === 'string'
        ? currentFilter.includes('filter=')
        : currentFilter?.filter
    ) {
      sortedOptionsWithDisabled.push({
        label: intl.formatMessage({ id: 'filter_group' }),
        value: LabelType.filter,
        disabled: false,
        checked: true,
        visible: true,
      });
    }
    return sortedOptionsWithDisabled;
  };

  const handleSaveFilter = (filterName: string, filter: undefined | string) => {
    let value = !!filter ? filter : location?.search?.substring(1);
    if (!value) {
      message.warning(intl.formatMessage({ id: 'no_selected_filters' }));
    }
    let jsonValue = handleReformatFilterValue(value);
    if (filterName && value) {
      let newFiltersObject: any = {};
      savedFilterObject?.length ??
        Object.keys(savedFilterObject).forEach(
          (key: any) => (newFiltersObject[key] = savedFilterObject[key])
        );
      newFiltersObject[filterName] = jsonValue;
      localStorage.setItem(
        `${page}-json-filters`,
        JSON.stringify(newFiltersObject)
      );
      getSavedFilters();
      form.resetFields();
      setOpen(false);
    }
  };

  const onSaveFinish = (values: any) => {
    let optionSelection = optionsWithDisabled.filter(
      (option) => option.checked
    );
    let filterString = location?.search?.substring(1);

    if (filterString) {
      let filterArr = filterString.split('&');
      let adjustedFilterString = '';
      optionSelection.forEach((checkbox, i) => {
        if (
          filterString.includes(checkbox.value.toString()) &&
          filterArr?.length > 0
        ) {
          adjustedFilterString = adjustedFilterString.concat(
            i === 0 ? '' : '&',
            filterArr.find((str) => str.includes(checkbox.value.toString()))!
          );
        }
      });
      handleSaveFilter(values.filterName, adjustedFilterString);
    } else {
      message.warning(intl.formatMessage({ id: 'no_selected_filters' }));
    }
  };

  const handleRemoveFilter = (filterName: string) => {
    if (filterName) {
      let newFiltersObject: any = {};
      Object.keys(savedFilterObject).forEach((key: any) => {
        if (key !== `${filterName}`) {
          newFiltersObject[key] = savedFilterObject[key];
        }
      });
      localStorage.setItem(
        `${page}-json-filters`,
        JSON.stringify(newFiltersObject)
      );
      getSavedFilters();
    }
  };

  const handleApplyFilter = (item: any) => {
    let currentUrlSetup = location?.search?.substring(1);
    const appliedFilterObj = savedFilterObject[item.key];
    if (currentUrlSetup) {
      let currentSetupObj = handleReformatFilterValue(currentUrlSetup);

      let newFiltObj = {
        ...appliedFilterObj,
        week: currentSetupObj?.week,
      };
      handleChangeUrl({
        weekNumber: newFiltObj?.week ?? '',
        branchId: newFiltObj?.branch ?? '',
        selectedPlId: newFiltObj?.plId ?? '',
        team: newFiltObj?.team ?? '',
        filter: newFiltObj?.filter,
        appointmentTypeId: newFiltObj?.AppointmentTypes ?? '',
      });
    } else {
      handleChangeUrl({
        weekNumber: appliedFilterObj?.week ?? '',
        branchId: appliedFilterObj?.branch ?? '',
        selectedPlId: appliedFilterObj?.plId ?? '',
        team: appliedFilterObj?.team ?? '',
        filter: appliedFilterObj?.filter ?? '',
        appointmentTypeId: appliedFilterObj?.AppointmentTypes ?? '',
      });
    }
  };

  useEffect(() => {
    getSavedFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open) {
      const filter = location?.search?.substring(1);
      if (filter) {
        const sortedOptionsWithDisabled = handleParsingFilterParameters(filter);
        setOptionsWithDisabled(sortedOptionsWithDisabled);
      } else {
        setOptionsWithDisabled([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (location?.search) {
      let urlSearch = location?.search?.substring(1);
      let { week, ...currentUrlSetup } = handleReformatFilterValue(urlSearch);
      let matchKeys: string[] = [];
      Object.keys(savedFilterObject).forEach((key) => {
        if (areEqualObjects(savedFilterObject[key], currentUrlSetup)) {
          matchKeys.push(key);
        }
      });
      setActiveFilterKeys([...matchKeys]);
    }
  }, [location?.search, savedFilterObject]);

  return (
    <div style={{ marginRight: '-12px' }}>
      <div style={{ display: 'flex' }}>
        <Dropdown
          arrow={false}
          trigger={['click']}
          overlayStyle={{
            minWidth: '200px',
            maxWidth: '400px',
            maxHeight: '450px',
            boxShadow: 'var(--box-shadow-container)',
            borderRadius: '6px',
          }}
          className={
            activeFilterKeys.length > 0
              ? styles.indicateSelected
              : styles.removeFocusColor
          }
          menu={{
            items: filtersDropdownMenuList,
            selectable: true,
            selectedKeys: activeFilterKeys,
            onSelect: handleApplyFilter,
          }}
        >
          <Button loading={loading}>
            {intl.formatMessage({ id: 'your_filters' })}
          </Button>
        </Dropdown>

        <Button
          onClick={() => setOpen(true)}
          className={styles.filterIconButton}
        >
          <div>
            <AddFilterIcon />
          </div>
        </Button>
      </div>
      <Modal
        title={intl.formatMessage({ id: 'manage_filters' })}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => {
          form.resetFields();
          setOpen(false);
        }}
        footer={null}
        bodyStyle={{
          marginRight: 0,
          padding: '20px 20px 0 20px',
          borderTop: '1px solid #F0F0F0',
          backgroundColor: '#fafafa',
        }}
        className={styles.saveFiltersModal}
        destroyOnClose
      >
        <Form
          form={form}
          layout="inline"
          onFinish={onSaveFinish}
          className={styles.saveFilterForm}
        >
          <div>
            <Text className={styles.savedFiltersText}>
              {intl.formatMessage({ id: 'create_new_preset' })}
            </Text>
          </div>
          <div className={styles.saveFilterInputs}>
            <div className={styles.saveFilterInputContainer}>
              <Form.Item
                name="filterName"
                label={intl.formatMessage({ id: 'title' })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'no_filter_name_error',
                    }),
                    validateTrigger: 'onSubmit',
                  },
                ]}
                style={{ flex: 1 }}
              >
                <Input
                  placeholder={intl.formatMessage({ id: 'filter_name' })}
                  allowClear
                />
              </Form.Item>
            </div>
          </div>
          <div>
            <div style={{ marginTop: '10px' }}>
              <Text className={styles.savedFiltersText}>
                {intl.formatMessage({ id: 'saved_filters' })}
              </Text>
            </div>
            <Space direction="vertical" className={styles.filterSpace}>
              {Object.keys(savedFilterObject).length > 0 ? (
                Object.keys(savedFilterObject).map((key, i) => {
                  return (
                    <Space
                      key={`saved-filter-${i}`}
                      className={styles.savedFilterListItem}
                    >
                      <Button
                        className={styles.filterModalLink}
                        ghost={!!activeFilterKeys.find((item) => item === key)}
                        type={
                          !!activeFilterKeys.find((item) => item === key)
                            ? 'primary'
                            : 'link'
                        }
                        onClick={() => {
                          handleApplyFilter({ key });
                          setOpen(false);
                        }}
                      >
                        {key}
                      </Button>
                      <Button
                        danger
                        type="text"
                        className={styles.saveFilterButton}
                        onClick={() => handleRemoveFilter(key)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Space>
                  );
                })
              ) : (
                <Text type="secondary">
                  {intl.formatMessage({ id: 'no_saved_filters' })}
                </Text>
              )}
            </Space>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '12px 20px',
              margin: '20px -20px 0 -20px',
              background: '#fff',
              borderTop: '1px solid #F0F0F0',
            }}
          >
            <Button
              style={{ marginRight: '8px' }}
              onClick={() => {
                form.resetFields();
                setOpen(false);
              }}
            >
              {intl.formatMessage({ id: 'cancel' })}
            </Button>
            <Form.Item shouldUpdate style={{ marginRight: 0 }}>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                >
                  {intl.formatMessage({ id: 'save' })}
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};
