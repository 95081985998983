import { AnswersDataState, IAppointmentStatus } from '../constants/types';
import translation from '../i18n/translation';

const date = new Date();
export const MODE =
  process.env.NODE_ENV === 'production'
    ? 'https://craftui-test.azurewebsites.net/'
    : 'http://localhost:3000/';

export const formatDate = (date: number) => (date < 10 ? `0${date}` : date);

export const getTheCurrentDay = () => {
  // get the current day
  const year = date.getUTCFullYear();
  const month = formatDate(date.getUTCMonth() + 1);
  const day = formatDate(date.getUTCDate());

  const startDate = `${year}-${month}-${day}T00:00:01.000Z`;
  const endDate = `${year}-${month}-${day}T23:59:59.000Z`;

  return { startDate, endDate };
};

export const getFormFieldLabel = (
  formRevisions: any,
  formFieldId: string = '',
  revisionId: string = ''
) => {
  const formRevision = formRevisions[revisionId];
  const workTypeName = formRevision.name;

  function printRec(obj: any): any {
    if (!obj.children?.length) {
      return;
    }
    /* eslint-disable */
    return obj.children.reduce(
      (total: any, current: any, index: number, arr: any) => {
        if (!current.children?.length) {
          if (current.id === formFieldId) {
            return {
              ...total,
              section: current?.label,
              workType: workTypeName,
              formField: current,
            };
          }
          return total;
        }

        if (current.children.some((item: any) => item.id === formFieldId)) {
          return {
            ...total,
            section: current?.label,
            workType: workTypeName,
            formField: current.children.find(
              (cur: any) => cur.id === formFieldId
            ),
          };
        } else {
          total = {
            ...total,
            ...current.children.reduce(
              (accu: object, res: any) => ({
                ...accu,
                section: total?.section,
                ...printRec(res),
              }),
              {}
            ),
          };
        }

        return total;
      },
      {}
    );
  }

  return printRec(formRevision);
};

export const getAnswersRevisionId = (answersData: AnswersDataState[]) => {
  return answersData.reduce(
    (total: Array<string>, current: AnswersDataState) => {
      return total.includes(current.formInstance.formRevisionId)
        ? total
        : [...total, current.formInstance.formRevisionId];
    },
    []
  );
};

export const getArrayRevisionUniqueId = (
  revisionId: Array<string>,
  revisionsStateId: Array<string>
) => {
  return revisionId.filter(
    (item: string) => revisionsStateId.indexOf(item) === -1
  );
};

export const isValidObjectId = (str?: string) => {
  if (str?.match(/^[0-9a-fA-F]{24}$/)) {
    return true;
  }
  return false;
};

export const isValidExtCaseId = (str: any) => {
  if (str?.match(/^DE+\d/i)) {
    return true;
  }
  return !isNaN(str);
};

export const getSortOrder = (
  workTypesOrder: any[],
  revisions: any,
  answers: any
) => {
  let fieldsOrder: any[] = [];

  workTypesOrder &&
    workTypesOrder.forEach((wt) => {
      const revision = revisions.filter((a: any) => a.workType.id == wt);
      if (revision && revision[0] && revision[0].children)
        fieldsOrder.push(...getFieldsOrder(revision[0].children));
    });

  const existFields = answers.map((a: any) => a.formFieldId);
  const existFieldsOrder = fieldsOrder.filter((value) =>
    existFields.includes(value)
  );

  return existFieldsOrder;
};

export const getFieldsOrder = (fields: any[]) => {
  let fieldsOrder: any[] = [];
  fields.forEach((f) => {
    if (f.type == 'Section' && f.children)
      fieldsOrder.push(...getFieldsOrder(f.children));
    else fieldsOrder.push(f.id);
  });

  return fieldsOrder;
};

export const groupBy = (items: any[], key: string) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export const getUniqueId = () =>
  Date.now().toString(36) + Math.random().toString(36).substring(2);

export const uniqByKeepFirst = (arr: any, key: (item: any) => string) => {
  let seen = new Set();
  return arr.filter((item: any) => {
    let k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
};

export const getNameInitials = (name: string) => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  let initials = [...name.matchAll(rgx)] || [];

  return (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();
};

export const getStatusColor = (
  status: string,
  showOpenColor: boolean = false
): string => {
  switch (status) {
    case 'Submitted': {
      return '#FFC700';
      //return '#808080';
    }
    case 'Resubmitted': {
      return '#FFC700';
    }
    case 'InReview': {
      return '#FFC700';
      //return '#3E7EFF';
    }
    case 'Approved': {
      return '#64D59F';
    }
    case 'Rejected': {
      return '#D72D2D';
    }
    case 'Open': {
      return showOpenColor ? '#A9A9A9' : '';
    }
    default:
      return '';
  }
};

export const getStatusAntDColor = (
  status: string,
  showOpenColor: boolean = false
): string => {
  switch (status) {
    case 'Submitted': {
      return 'warning';
    }
    case 'Resubmitted': {
      return 'warning';
    }
    case 'InReview': {
      return 'warning';
    }
    case 'Approved': {
      return 'success';
    }
    case 'Rejected': {
      return 'error';
    }
    case 'Open': {
      return showOpenColor ? 'default' : '';
    }
    default:
      return '';
  }
};

export const findStatusTagLabel = (status: string) => {
  switch (status) {
    case 'Rejected': {
      return translation('rejected');
    }
    case 'Approved': {
      return translation('approved');
    }
    case 'InReview': {
      return translation('in_review');
    }
    case 'Submitted': {
      return translation('submitted');
    }
    case 'Resubmitted': {
      return translation('re_submitted');
    }
    default:
      return '';
  }
};

export const getAppointmentStatusData = (
  status: string
): {
  statusColorType?: string;
  i18nKey?: string;
  tooltipKey?: string;
} => {
  switch (status) {
    case IAppointmentStatus.cancelled:
      return { statusColorType: 'error' };
    case IAppointmentStatus.cannotComplete:
      return { statusColorType: 'error' };
    case IAppointmentStatus.completed:
      return { statusColorType: 'success' };
    case IAppointmentStatus.inProgress:
      return { statusColorType: 'warning' };
    case IAppointmentStatus.technicalCancellation:
      return {
        statusColorType: 'error',
        i18nKey: 'technical_cancellation',
        tooltipKey: 'technical_cancellation_tooltip',
      };
    case IAppointmentStatus.customerCancellation:
      return {
        statusColorType: 'error',
        i18nKey: 'customer_cancellation',
        tooltipKey: 'customer_cancellation_tooltip',
      };
    case IAppointmentStatus.installationPreparation:
      return {
        i18nKey: 'installation_preparation',
        tooltipKey: 'installation_preparation_tooltip',
      };
    case IAppointmentStatus.closed:
      return { statusColorType: 'success' };
    default:
      return { statusColorType: 'default' };
  }
};

export const getListHueOfNumbers = () => {
  let list: number[] = [];
  let previousNumber = 0;
  const amountOfNumbers = 40;
  const increasedAmount = 20;
  const maxAmount = 360;

  const setNumberInArray = (number: number) => {
    list.push(number);
    previousNumber = number;
  };

  for (let i = 1; i < amountOfNumbers; i++) {
    // Reset when max hue is reached
    if (previousNumber > maxAmount - increasedAmount) {
      setNumberInArray(1);
    }
    // Skip some green hues
    if (previousNumber > 80 && previousNumber < 170) {
      setNumberInArray(170);
    } else {
      const nextNumber = previousNumber + increasedAmount;
      setNumberInArray(nextNumber);
    }
  }

  return list;
};
/*
  Function to compare 2 comma separated strings to be equal irrespective of index
  "apple,orange,banana" is same as "orange,banana,apple"
*/

export const isSameCommaSparatedStrings = (
  string1: string,
  string2: string
): boolean =>
  !!string1 &&
  !!string2 &&
  string1.split(',').sort() === string2.split(',').sort();

/*
const obj1 = { name: 'John', age: 30 };
const obj2 = { name: 'John', age: 30, gender: 'male' };
const obj3 = { name: 'Jane', age: 25 };

console.log(isSubset(obj1, obj2)); // true
console.log(isSubset(obj1, obj3)); // false
*/
export const isSubset = (subSetObj: any, superSetObj: any) =>
  Object.keys(subSetObj).every(
    (key) =>
      superSetObj.hasOwnProperty(key) &&
      (subSetObj[key] === superSetObj[key] ||
        isSameCommaSparatedStrings(subSetObj[key], superSetObj[key]))
  );

/*
  const obj1 = { name: 'John', age: 30, arr: [2,1,4] };
  const obj2 = { name: 'John', age: 30, arr: [2,1,4], place: 'India' };
  const obj3 = { age: 25, name: 'John',  arr: [4,1,2]};

  if (areObjectsEqual(obj1, obj3)) {
    console.log("obj1 and obj3 are the same.");
  } else {
    console.log("obj1 and obj3 are different.");
  }

  if (areObjectsEqual(obj1, obj2)) {
    console.log("obj1 and obj2 are the same.");
  } else {
    console.log("obj1 and obj2 are different.");
  }
*/

export const areEqualObjects = <T extends Record<string, string>>(
  obj1: T,
  obj2: T
): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  return (
    keys1.length === keys2.length &&
    keys1.every(
      (key) =>
        obj1[key] === obj2[key] ||
        isSameCommaSparatedStrings(obj1[key], obj2[key])
    )
  );
};

export const domainIncludes = (domain: string): boolean =>
  window.location.origin.includes(domain);
