import { FC, useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, Radio, Space, Typography } from 'antd';
import {
  AppointmentType,
  IAppointmentStatus,
  IAppointmentStatusType,
  IAppointmentTypeSalesForce,
} from '../../constants/types';
import translation from '../../i18n/translation';
import ApiRepository from '../../services/api/apiRepository';
import { showNotificationMessage } from '../../utils/notification';
import { useIntl } from 'react-intl';
import { SearchOutlined, SwapRightOutlined } from '@ant-design/icons';
import styles from './delayAndStatusModal.module.scss';
import { CONSTANTS, getTranslation, ICancellationReasons } from 'craftos-ui';
import Tree, { DataNode } from 'antd/lib/tree';
import StatusTag from '../statusTag';
import { getAppointmentStatusData } from '../../utils/helpers';
import StatusChangeNotification from './components/statusChangeNotification';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { IFeatures } from '../../store/rolesAndPrivileges';

const { Text } = Typography;

interface Props {
  appointment?: AppointmentType | null;
  workOrderId?: string;
  modalOpen: boolean;
  setModalOpen: (arg: boolean) => void;
  delayReasonIsRequired?: boolean;
  type?: IAppointmentStatusType | 'delay';
  status?: IAppointmentStatus;
  onSuccess?: ((reloadCache?: boolean) => void) | null;
  currentStatus?: IAppointmentStatus;
  newStatus?: IAppointmentStatus | undefined;
  cannotCompleteReasonCodeList: ICancellationReasons | null;
  onCancel?: (reloadCache: boolean) => void;
}

const WidgetDelayAndStatusModal: FC<Props> = ({
  appointment,
  modalOpen,
  setModalOpen,
  delayReasonIsRequired = false,
  type,
  status = IAppointmentStatus.none,
  workOrderId,
  onSuccess,
  currentStatus,
  newStatus,
  cannotCompleteReasonCodeList,
  onCancel,
}) => {
  const intl = useIntl();
  const inputRef = useRef(null);
  const privilegesState = useSelector(
    (state: RootState) => state.authentication?.privileges ?? {}
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [delayReasonIsSubmitted, setDelayReasonIsSubmitted] = useState<boolean>(
    !delayReasonIsRequired
  );
  const [reasonCodeList, setReasonCodeList] = useState<ICancellationReasons>(
    []
  );
  const [
    filteredReasonCodes,
    setFilteredReasonCodes,
  ] = useState<ICancellationReasons>(
    !delayReasonIsRequired && cannotCompleteReasonCodeList
      ? cannotCompleteReasonCodeList
      : []
  );
  const [customReasonComments, setCustomReasonComments] = useState<string>('');
  const [selectedReasons, setSelectedReasons] = useState<React.Key[]>();
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const clearLocalStateVariables = () => {
    setCustomReasonComments('');
    setSelectedReasons([]);
    setExpandedKeys([]);
    setLoading(false);
  };
  useEffect(() => {
    if (!modalOpen) {
      clearLocalStateVariables();
      setFilteredReasonCodes([]);
    } else {
      if (appointment?.serviceAppointmentType) {
        let reasons =
          type === 'delay' || (delayReasonIsRequired && !delayReasonIsSubmitted)
            ? CONSTANTS.DELAY_REASON_CODE[appointment?.appointmentType?.name] ??
              CONSTANTS.DELAY_REASON_CODE[
                appointment?.serviceAppointmentType
              ] ??
              CONSTANTS.DELAY_REASON_CODE[
                appointment?.serviceAppointmentType?.replace(' Backlog', '')
              ] ??
              CONSTANTS.DELAY_REASON_CODE.default
            : cannotCompleteReasonCodeList ?? [];
        setReasonCodeList(reasons ?? []);
        setFilteredReasonCodes(reasons ?? []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen, delayReasonIsSubmitted]);

  const onReasonCodeSearch = (value: string) => {
    const reasonSearchValue = value.toLowerCase();
    if (reasonSearchValue) {
      if (Array.isArray(reasonCodeList)) {
        setFilteredReasonCodes(
          reasonCodeList.filter((reason: string) =>
            reasonSearchValue
              ? getTranslation(reason).toLowerCase().includes(reasonSearchValue)
              : reason
          )
        );
        return;
      }

      let newObj: any = {};
      setAutoExpandParent(true);
      Object.keys(reasonCodeList ?? []).forEach((reason: string) => {
        let arr = reasonCodeList![reason].filter((item: string) =>
          getTranslation(item).toLowerCase().includes(reasonSearchValue)
        );
        if (arr.length > 0) newObj[reason] = arr;
      });
      setFilteredReasonCodes({ ...newObj });
      setExpandedKeys(Object.keys(newObj));
    } else {
      setFilteredReasonCodes(reasonCodeList ?? []);
      setExpandedKeys(
        selectedReasons?.length && selectedReasons[0]
          ? [
              selectedReasons[0]
                .toString()
                .split(CONSTANTS.REASON_SEPARATOR_DELIMITER)[0],
            ]
          : []
      );
    }
  };

  const handleDisplayUpdateButton = () => {
    if (type === 'delay' || appointment?.appointmentType?.name !== 'Montage') {
      return true;
    } else if (delayReasonIsRequired && !delayReasonIsSubmitted) {
      return true;
    } else {
      if (
        appointment?.serviceAppointmentType ===
          IAppointmentTypeSalesForce.clarification ||
        privilegesState[IFeatures.progressBypassStatusChangeValidation]
      ) {
        // no automation for Klärfall and no restrictions for BranchLead role:
        return true;
      } else if (
        [
          IAppointmentStatus.technicalCancellation,
          IAppointmentStatus.customerCancellation,
          IAppointmentStatus.cannotComplete,
          IAppointmentStatus.backlog,
        ].includes(status!)
          ? //we can change to inProgress
            newStatus !== IAppointmentStatus.completed
          : //no manual change to inProgress or Completed
            ![
              IAppointmentStatus.completed,
              IAppointmentStatus.inProgress,
            ].includes(newStatus!)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const generateDataNode = (
    rootKey: string,
    isRoot: boolean,
    parentKey: string
  ): DataNode => {
    let childItems = Array.isArray(filteredReasonCodes)
      ? []
      : filteredReasonCodes[rootKey]
      ? filteredReasonCodes[rootKey]
      : [];
    let isSection = isRoot && !!childItems.length;
    return {
      title: !isSection ? (
        <Radio
          checked={selectedReasons?.includes(
            `${parentKey}${CONSTANTS.REASON_SEPARATOR_DELIMITER}${rootKey}`
          )}
          style={{ marginLeft: '-22px' }}
        >
          {getTranslation(rootKey)}
        </Radio>
      ) : (
        <Text>{getTranslation(rootKey)}</Text>
      ),
      key: isSection
        ? rootKey
        : `${parentKey}${CONSTANTS.REASON_SEPARATOR_DELIMITER}${rootKey}`,
      checkable: !isSection,
      selectable: !(isRoot && childItems.length),
      children: isSection
        ? childItems.map((key: string) => generateDataNode(key, false, rootKey))
        : [],
    };
  };

  const handleSubmitDelayedReason = async () => {
    setLoading(true);
    try {
      const result = await ApiRepository.updateAppointmentDelayReason(
        appointment?.id ?? '',
        !!selectedReasons?.length
          ? `${selectedReasons[0]}${
              customReasonComments ? CONSTANTS.REASON_SEPARATOR_DELIMITER : ''
            }${customReasonComments}`
          : ''
      );
      if (result?.response?.length > 0) setLoading(false);
      if (!delayReasonIsRequired) {
        if (onCancel) onCancel(true);
        clearLocalStateVariables();
      } else {
        clearLocalStateVariables();
        setDelayReasonIsSubmitted(true);
      }
    } catch (error: any) {
      clearLocalStateVariables();
      setLoading(false);
      setModalOpen(false);
      showNotificationMessage({
        message: intl.formatMessage({
          id: error.errorKey ?? 'error_update_delayed_reason',
        }),
        error: error,
      });
    }
  };

  const handleSubmitCannotCompleteReasons = async (statusValue: string) => {
    try {
      await ApiRepository.updateEntityStatus(
        statusValue,
        type ?? '',
        (type === IAppointmentStatusType.workOrder
          ? appointment?.additionalInformation?.workOrderId ?? workOrderId
          : appointment?.additionalInformation?.serviceAppointmentId) ?? '',
        status ?? IAppointmentStatus.none,
        !!selectedReasons?.length
          ? `${selectedReasons[0]}${
              customReasonComments ? CONSTANTS.REASON_SEPARATOR_DELIMITER : ''
            }${customReasonComments}`
          : ''
      );
      setLoading(false);
      if (onSuccess) {
        onSuccess(true);
      }
      setModalOpen(false);
    } catch (error: any) {
      setLoading(false);
      if (onCancel) onCancel(false);
      showNotificationMessage({
        message: intl.formatMessage({
          id: error.errorKey ?? 'error_update_entity_status',
        }),
        error: error,
      });
    }
  };

  const onConfirm = () => {
    let statusValue = newStatus ?? IAppointmentStatus.none;
    setLoading(true);
    handleSubmitCannotCompleteReasons(statusValue);
  };

  const renderStatusTag = (
    status: string,
    showDropdownArrow: boolean = false
  ) => {
    let { statusColorType, i18nKey, tooltipKey } = getAppointmentStatusData(
      status
    );
    return (
      <StatusTag
        id={`ga-${type}-${status}`}
        status={statusColorType ?? 'default'}
        tooltip={tooltipKey ? intl.formatMessage({ id: tooltipKey }) : ''}
        isLoading={loading}
        showDropdownArrow={showDropdownArrow}
      >
        {i18nKey ? translation(i18nKey) : status}
      </StatusTag>
    );
  };

  const onExpandReasonsTreeNodes = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const generateTreeNodesData = (): DataNode[] => {
    return Array.isArray(filteredReasonCodes)
      ? filteredReasonCodes.map((key: string) =>
          generateDataNode(key, false, '')
        )
      : Object.keys(filteredReasonCodes).map((key: string) =>
          generateDataNode(key, true, '')
        );
  };

  const makeReasonSelectionComponent = (mode: string) => {
    const treeData: DataNode[] = generateTreeNodesData();
    return (
      <Space direction="vertical" style={{ width: '500px', paddingTop: 0 }}>
        <Text className={styles.heading}>
          {mode === 'delay'
            ? translation('choose_delayed_reason')
            : type === IAppointmentStatusType.serviceAppointment
            ? translation('update_appointment_status')
            : translation('update_workorder_status')}
        </Text>
        {mode !== 'delay' && (
          <Space style={{ margin: '8px 0' }}>
            {renderStatusTag(currentStatus ?? '')}
            <SwapRightOutlined />
            {renderStatusTag(newStatus!)}
          </Space>
        )}
        <>
          {mode !== 'delay' && (
            <Space>
              <Text className={styles.secondary}>
                {translation('choose_cannot_complete_reason')}
              </Text>
            </Space>
          )}
          <Text>
            {translation('select_reason')}{' '}
            <span style={{ color: 'red' }}>*</span>
          </Text>
          <Input
            placeholder="Suche"
            onChange={(e) => onReasonCodeSearch(e.target.value)}
            allowClear
            style={{ width: '95%', borderRadius: '4px' }}
            suffix={<SearchOutlined />}
          />
          <div className={styles.radioGroup}>
            <Tree
              multiple={false}
              autoExpandParent={autoExpandParent}
              selectedKeys={selectedReasons}
              expandedKeys={expandedKeys}
              treeData={treeData}
              blockNode
              onSelect={setSelectedReasons}
              selectable={false}
              onExpand={onExpandReasonsTreeNodes}
            />
          </div>
          <Space direction="vertical">
            <Space className={styles.modalComment}>
              {translation('btn_comment')}(optional)
            </Space>
            <Input.TextArea
              ref={inputRef}
              allowClear
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder=""
              style={{
                width: '475px',
                borderRadius: '4px',
                marginBottom: '12px',
              }}
              value={customReasonComments}
              onChange={(e) => {
                setCustomReasonComments(e.target.value);
              }}
              maxLength={140}
              showCount={true}
            />
          </Space>
        </>
      </Space>
    );
  };

  const resctrictStatusChange = () => {
    if (appointment?.appointmentType?.name !== 'Montage') {
      return false;
    } else if (
      appointment?.serviceAppointmentType ===
        IAppointmentTypeSalesForce.clarification ||
      privilegesState[IFeatures.progressBypassStatusChangeValidation]
    ) {
      return false;
    } else {
      if (type === IAppointmentStatusType.serviceAppointment) {
        return (
          status === IAppointmentStatus.cannotComplete
            ? newStatus === IAppointmentStatus.completed
            : [
                IAppointmentStatus.completed,
                IAppointmentStatus.inProgress,
              ].includes(newStatus!)
        )
          ? true
          : false;
      } else {
        return (
          [
            IAppointmentStatus.customerCancellation,
            IAppointmentStatus.backlog,
            IAppointmentStatus.technicalCancellation,
          ].includes(status!)
            ? newStatus === IAppointmentStatus.completed
            : [
                IAppointmentStatus.completed,
                IAppointmentStatus.inProgress,
              ].includes(newStatus!)
        )
          ? true
          : false;
      }
    }
  };

  const makeConfirmText = () =>
    resctrictStatusChange() ? (
      <StatusChangeNotification status={newStatus!} />
    ) : (
      <Space direction="vertical" style={{ width: '500px', paddingTop: 0 }}>
        <Text className={styles.heading}>
          {type === IAppointmentStatusType.serviceAppointment
            ? translation('update_appointment_status')
            : translation('update_workorder_status')}
        </Text>
        <Space style={{ margin: '8px 0' }}>
          {renderStatusTag(currentStatus ?? '')}
          <SwapRightOutlined />
          {renderStatusTag(newStatus!)}
        </Space>
      </Space>
    );

  return (
    <Modal
      open={modalOpen}
      title={
        (!delayReasonIsRequired && type === 'delay') ||
        (delayReasonIsRequired && !delayReasonIsSubmitted)
          ? intl.formatMessage({ id: 'delayed_reason_update' })
          : type === IAppointmentStatusType.serviceAppointment
          ? intl.formatMessage({ id: 'appointment_status_update' })
          : intl.formatMessage({ id: 'workorder_status_update' })
      }
      onCancel={() => {
        setDelayReasonIsSubmitted(false);
        if (onCancel) {
          onCancel(delayReasonIsRequired && delayReasonIsSubmitted);
        }
      }}
      destroyOnClose={true}
      style={{ borderRadius: '4px' }}
      footer={
        <Space className={styles.modalFooter}>
          <Text>
            {type === 'delay' || !delayReasonIsRequired
              ? null
              : delayReasonIsRequired && !delayReasonIsSubmitted
              ? '1 / 2'
              : '2 / 2'}
          </Text>
          <Space className={styles.footerButtonsContainer}>
            <Button
              key="back"
              onClick={() => {
                if (onCancel) {
                  onCancel(delayReasonIsRequired && delayReasonIsSubmitted);
                }
                setDelayReasonIsSubmitted(false);
              }}
            >
              {translation('cancel_editing')}
            </Button>
            {handleDisplayUpdateButton() && (
              <Button
                key="submit"
                type="primary"
                loading={loading}
                id={`ga-${type}-button-ok-${newStatus}`}
                disabled={
                  ((type === 'delay' ||
                    (type === IAppointmentStatusType.serviceAppointment &&
                      newStatus === IAppointmentStatus.cannotComplete) ||
                    (delayReasonIsRequired && !delayReasonIsSubmitted)) &&
                    !!reasonCodeList &&
                    !selectedReasons?.length) ||
                  loading
                    ? true
                    : false
                }
                onClick={
                  type === 'delay' ||
                  (delayReasonIsRequired && !delayReasonIsSubmitted)
                    ? handleSubmitDelayedReason
                    : onConfirm
                }
              >
                {translation('update')}
              </Button>
            )}
          </Space>
        </Space>
      }
    >
      {type === 'delay' || (delayReasonIsRequired && !delayReasonIsSubmitted)
        ? makeReasonSelectionComponent('delay')
        : type === IAppointmentStatusType.serviceAppointment &&
          newStatus === IAppointmentStatus.cannotComplete &&
          !!reasonCodeList
        ? makeReasonSelectionComponent(type)
        : makeConfirmText()}
    </Modal>
  );
};

export default WidgetDelayAndStatusModal;
