import moment from 'moment';
import { AnyAction } from 'redux';
import {
  progressTableTypes,
  progressTeamsTypes,
  AppointmentsTeamsState,
  ProgressTableState,
  IAppointmentStatus,
  IAppointment,
  IAppointmentTypeSalesForce,
  IStatus,
  ACM_VIDEO,
} from '../../constants/types';
import { getStatusAntDColor, getUniqueId, groupBy } from '../../utils/helpers';

const WO_STATUSES = {
  new: IAppointmentStatus.new,
  in_progress_WO: IAppointmentStatus.inProgress,
  technical_cancellation: IAppointmentStatus.technicalCancellation,
  customer_cancellation: IAppointmentStatus.customerCancellation,
  wo_backlog: IAppointmentStatus.backlog,
  completed_WO: IAppointmentStatus.completed,
  installation_preparation: IAppointmentStatus.installationPreparation,
};

const APPOINTMENT_STATUSES = {
  scheduled: IAppointmentStatus.scheduled,
  in_progress: IAppointmentStatus.inProgress,
  cannotComplete: IAppointmentStatus.cannotComplete,
  completed_app: IAppointmentStatus.completed,
};

const initialState: ProgressTableState = {
  loading: false,
  error: null,
  total: 0,
  filteredTotal: 0,
  completed: 0,
  filters: [],
  appointmentTypes: [],
  montageAppointmentId: '',
  appointmentsTableData: [],
  workOrderStatusList: [],
  appointmentStatusList: [],
  initialData: [],
  flatFilter: {},
  filterTree: {
    scheduled_appointments: {
      completed: {
        eval: (field: any) =>
          field?.additionalInformation?.appointmentStatus ===
          IAppointmentStatus.completed,
        filterIds: [],
      },
      active_appointments: {
        eval: (field: any) => field?.status === 'Active',
        filterIds: [],
      },
      delayed_appointments: {
        eval: (field: any) => !!field?.isDelayedStart || !!field?.isDelayedEnd,
        filterIds: [],
      },
    },

    completion_goal: {
      at_risk: {
        eval: (field: any) => field?.atRisk,
        filterIds: [],
      },
      start_date_modified: {
        eval: (field: any) =>
          field?.appointmentDate &&
          field?.appointmentScheduledDate &&
          field?.appointmentDate !== field?.appointmentScheduledDate,
        filterIds: [],
      },
      end_date_modified: {
        eval: (field: any) =>
          field?.appointmentEndDate &&
          field?.appointmentScheduledEndDate &&
          field?.appointmentEndDate !== field?.appointmentScheduledEndDate,
        filterIds: [],
      },
      last_appointments: {
        eval: (field: any) => field?.isLastAppointment,
        filterIds: [],
      },
    },

    appointment_type: {
      with_wallbox: {
        eval: (field: any) => field?.wallbox === 'Ja',
        filterIds: [],
      },
      with_meter_cabinet_exchange: {
        eval: (field: any) => field?.meterExchangeCabinet === 'Ja',
        filterIds: [],
      },
    },

    qm_approval: {
      qm_rejection: {
        eval: (field: any) => field?.rejection,
        filterIds: [],
      },
      question: {
        eval: (field: any) => field?.questions,
        filterIds: [],
      },
      // long_wait_time: {
      //   eval: (field: any) => false,
      //   filterIds: [],
      // },
    },

    appointment_type_filter: {
      dropdown: true,
      ...(Object.keys(IAppointmentTypeSalesForce) as Array<
        keyof typeof IAppointmentTypeSalesForce
      >).reduce((result, key) => {
        result[key] = {
          eval: (field: any) =>
            field?.serviceAppointmentType === IAppointmentTypeSalesForce[key],
          filterIds: [],
        };
        return result;
      }, {} as Record<keyof typeof IAppointmentTypeSalesForce, any>),
    },

    appointment_status: {
      dropdown: true,
      ...(Object.keys(APPOINTMENT_STATUSES) as Array<
        keyof typeof APPOINTMENT_STATUSES
      >).reduce((result, key) => {
        result[key] = {
          eval: (field: any) =>
            field?.additionalInformation?.appointmentStatus ===
            APPOINTMENT_STATUSES[key],
          filterIds: [],
        };
        return result;
      }, {} as Record<keyof typeof APPOINTMENT_STATUSES, any>),
    },

    wo_status: {
      dropdown: true,
      ...(Object.keys(WO_STATUSES) as Array<keyof typeof WO_STATUSES>).reduce(
        (result, key) => {
          result[key] = {
            eval: (field: any) =>
              field?.additionalInformation?.workOrderStatus ===
              WO_STATUSES[key],
            filterIds: [],
          };
          return result;
        },
        {} as Record<keyof typeof WO_STATUSES, any>
      ),
    },

    // punctuality: {
    //   started_late: {
    //     eval: (field: any) => false,
    //     filterIds: [],
    //   },
    //   overdue: {
    //     eval: (field: any) => false,
    //     filterIds: [],
    //   },
    // },

    dc_status: {
      dropdown: true,
      uk_open: {
        eval: (field: any) => field?.UK?.status === IStatus.open,
        filterIds: [],
      },
      uk_submitted: {
        eval: (field: any) =>
          field?.UK?.status === IStatus.submitted ||
          field?.UK?.status === IStatus.reSubmitted ||
          field?.UK?.status === IStatus.inReview,
        filterIds: [],
      },
      uk_approved: {
        eval: (field: any) => field?.UK?.status === IStatus.approved,
        filterIds: [],
      },
      uk_rejected: {
        eval: (field: any) => field?.UK?.status === IStatus.rejected,
        filterIds: [],
      },
      dc_open: {
        eval: (field: any) => field?.DC?.status === IStatus.open,
        filterIds: [],
      },
      dc_submitted: {
        eval: (field: any) =>
          field?.DC?.status === IStatus.submitted ||
          field?.DC?.status === IStatus.reSubmitted ||
          field?.DC?.status === IStatus.inReview,
        filterIds: [],
      },
      dc_approved: {
        eval: (field: any) => field?.DC?.status === IStatus.approved,
        filterIds: [],
      },
      dc_rejected: {
        eval: (field: any) => field?.DC?.status === IStatus.rejected,
        filterIds: [],
      },
    },

    ac_status: {
      dropdown: true,
      ac_open: {
        eval: (field: any) => field?.AC?.status === IStatus.open,
        filterIds: [],
      },
      ac_submitted: {
        eval: (field: any) =>
          field?.AC?.status === IStatus.submitted ||
          field?.AC?.status === IStatus.reSubmitted ||
          field?.AC?.status === IStatus.inReview,
        filterIds: [],
      },
      ac_approved: {
        eval: (field: any) => field?.AC?.status === IStatus.approved,
        filterIds: [],
      },
      ac_rejected: {
        eval: (field: any) => field?.AC?.status === IStatus.rejected,
        filterIds: [],
      },
    },

    acm_status: {
      dropdown: true,
      acm_open: {
        eval: (field: any) => field['AC-M']?.status === IStatus.open,
        filterIds: [],
      },
      acm_submitted: {
        eval: (field: any) =>
          field['AC-M']?.status === IStatus.submitted ||
          field['AC-M']?.status === IStatus.reSubmitted ||
          field['AC-M']?.status === IStatus.inReview,
        filterIds: [],
      },
      acm_approved: {
        eval: (field: any) => field['AC-M']?.status === IStatus.approved,
        filterIds: [],
      },
      acm_rejected: {
        eval: (field: any) => field['AC-M']?.status === IStatus.rejected,
        filterIds: [],
      },
    },

    completion_goal_on: {
      dropdown: true,
      monday: {
        eval: (field: any) =>
          moment(field.appointmentEndDate)
            .startOf('week')
            .isSame(field.appointmentEndDate, 'day'),
        filterIds: [],
      },
      tuesday: {
        eval: (field: any) =>
          moment(field.appointmentEndDate)
            .startOf('week')
            .add(1, 'days')
            .isSame(field.appointmentEndDate, 'day'),
        filterIds: [],
      },
      wednesday: {
        eval: (field: any) =>
          moment(field.appointmentEndDate)
            .startOf('week')
            .add(2, 'days')
            .isSame(field.appointmentEndDate, 'day'),
        filterIds: [],
      },
      thursday: {
        eval: (field: any) =>
          moment(field.appointmentEndDate)
            .startOf('week')
            .add(3, 'days')
            .isSame(field.appointmentEndDate, 'day'),
        filterIds: [],
      },
      friday: {
        eval: (field: any) =>
          moment(field.appointmentEndDate)
            .startOf('week')
            .add(4, 'days')
            .isSame(field.appointmentEndDate, 'day'),
        filterIds: [],
      },
      saturday: {
        eval: (field: any) =>
          moment(field.appointmentEndDate)
            .startOf('week')
            .add(5, 'days')
            .isSame(field.appointmentEndDate, 'day'),
        filterIds: [],
      },
    },
    acm_video_status: {
      dropdown: true,
      acm_video_open: {
        eval: (field: any) =>
          field?.serviceAppointmentType!.includes('ACM') &&
          field[ACM_VIDEO]?.status === IStatus.open,
        filterIds: [],
      },
      acm_video_submitted: {
        eval: (field: any) =>
          field?.serviceAppointmentType!.includes('ACM') &&
          (field[ACM_VIDEO]?.status === IStatus.submitted ||
            field[ACM_VIDEO]?.status === IStatus.reSubmitted ||
            field[ACM_VIDEO]?.status === IStatus.inReview),
        filterIds: [],
      },
      acm_video_approved: {
        eval: (field: any) =>
          field?.serviceAppointmentType!.includes('ACM') &&
          field[ACM_VIDEO]?.status === IStatus.approved,
        filterIds: [],
      },
      acm_video_rejected: {
        eval: (field: any) =>
          field?.serviceAppointmentType!.includes('ACM') &&
          field[ACM_VIDEO]?.status === IStatus.rejected,
        filterIds: [],
      },
    },
  },
};

Object.values(initialState.filterTree).forEach((filterObj: any) =>
  Object.assign(initialState.flatFilter, {
    ...initialState.flatFilter,
    ...(({ dropdown, ...obj }) => obj)(filterObj),
  })
);

const initialTeamsState: AppointmentsTeamsState = {
  loading: false,
  error: null,
  appointmentsTeamsData: [],
  teamGroups: null,
  branchTLGroups: null,
  teamLeads: null,
};

const getGroupStatuses = (appointment: any): any => {
  let groupStatus = {};
  let lastFieldUpdateTime = '';
  if (appointment && appointment.groupStatus) {
    appointment.groupStatus.forEach((group: any) => {
      lastFieldUpdateTime =
        group.updatedTime > lastFieldUpdateTime
          ? group.updatedTime
          : lastFieldUpdateTime;
      groupStatus = {
        ...groupStatus,
        [group.name]: {
          ...group,
          statusColor: getStatusAntDColor(group.status, true),
        },
      };
    });
  }
  return { ...groupStatus, lastFieldUpdateTime };
};

const formatAppointmentResponse = (appointment: IAppointment): any => ({
  ...appointment,
  ...getGroupStatuses(appointment),
  key: appointment.id || getUniqueId(), //appointment.externalId ? after BE fix
  name: `${appointment.customer?.lastName}, ${appointment.customer?.firstName}`,
  appointmentDate: appointment.appointmentDate,
  address: appointment.customerAddress?.address1 ?? '',
  zip: appointment.customerAddress?.zipCode ?? '',
  genericAppointmentType:
    appointment?.serviceAppointmentType ===
    IAppointmentTypeSalesForce.clarification
      ? appointment?.serviceAppointmentType
      : appointment.appointmentType?.name,
  appointment: appointment.appointmentType?.name,
  answerCounts: appointment?.answerCounts,
  module:
    appointment.numberOfModules || appointment.numberOfModules === '0'
      ? appointment.numberOfModules
      : '--',
  teamName: appointment.team?.name,
  note: appointment?.note,
  calculatedApproved:
    typeof appointment.answerCounts?.approved === 'number'
      ? appointment.answerCounts?.approved
      : '-',
  calculatedTotal:
    typeof appointment.answerCounts?.total === 'number'
      ? appointment.answerCounts?.approvalPending +
        (appointment.appointmentType?.name === 'Sep. Zählertausch' || 'MVT'
          ? appointment.answerCounts?.openRequired
          : appointment.answerCounts?.openApprovable) +
        appointment.answerCounts?.rejected +
        appointment.answerCounts?.approved
      : '-',
  isDelayedEnd: appointment.isDelayedEnd,
  isDelayedStart: appointment.isDelayedStart,
});

const resetFilterIds = () => {
  Object.values(initialState.flatFilter).forEach((filter: any) => {
    filter.filterIds = [];
  });
};

const applyFilter = (
  appointment: any,
  filters: string[],
  customerSearch: string,
  isDataFetched: boolean
): boolean => {
  let result = true;
  Object.keys(initialState.filterTree).forEach((rootKey: string) => {
    let rootVals = initialState.filterTree[rootKey];
    let filterResult =
      filters.filter((k: string) => Object.keys(rootVals).includes(k))
        .length === 0;
    Object.keys(rootVals).forEach((key: string) => {
      let item = rootVals[key];
      if (typeof item === 'object' && item.eval(appointment)) {
        if (isDataFetched) {
          item.filterIds.push(appointment.externalId);
        }
        filterResult = filterResult || filters.includes(key);
      }
    });
    result = result && filterResult;
  });

  if (customerSearch) {
    let searchVal = customerSearch.trim().toLowerCase();

    result =
      result &&
      (appointment.externalCaseId?.trim().toLowerCase().includes(searchVal) ||
        appointment.name?.trim().toLowerCase().includes(searchVal) ||
        appointment.address?.trim().toLowerCase().includes(searchVal) ||
        appointment.mostRecentWorkOrderNote?.text
          ?.trim()
          .toLowerCase()
          .includes(searchVal));
  }

  return result;
};

const groupAppointmentByTeamName = (
  response: any,
  filters: string[],
  customerSearch: string,
  isDataFetched: boolean
): {
  appointmentsTableData: IAppointment[];
  filteredTotal: number;
  completed: number;
} => {
  let appointmentsTableData: any = [],
    filteredTotal = 0,
    completed = 0;
  let previousTeamId: string = '';
  if (isDataFetched) {
    resetFilterIds();
  }
  let routeAddressList = [];
  response &&
    response.forEach((appointment: any) => {
      let formattedAppointment = formatAppointmentResponse(appointment);
      if (
        !applyFilter(
          formattedAppointment,
          filters,
          customerSearch,
          isDataFetched
        )
      ) {
        return;
      }

      if (previousTeamId !== appointment.team.id) {
        routeAddressList = [appointment.customerAddress.address1];
        appointmentsTableData.push(
          formatAppointmentResponse({
            ...appointment,
            questions: false,
            id: getUniqueId(),
            isGroupHeader: true,
            routeAddressList,
          })
        );
        previousTeamId = appointment.team.id;
      } else {
        routeAddressList.push(appointment.customerAddress.address1);
      }
      appointmentsTableData.push(formattedAppointment);
      filteredTotal++;
      if (
        appointment?.additionalInformation?.appointmentStatus ===
        IAppointmentStatus.completed
      ) {
        completed++;
      }
    });

  return { appointmentsTableData, filteredTotal, completed };
};

export const progressTableReducer = (
  state = initialState,
  action: AnyAction
) => {
  const { type, payload } = action;

  switch (type) {
    case progressTableTypes.GET_PROGRESS_DATA:
      let { response, filters, customerSearch } = payload;
      return {
        ...state,
        ...groupAppointmentByTeamName(response, filters, customerSearch, true),
        total: payload.response.length,
        filters,
        customerSearch,
        error: null,
        loading: false,
        initialData: payload.response,
      };
    case progressTableTypes.SET_PROGRESS_DATA_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case progressTableTypes.APPLY_PROGRESS_FILTER:
      return {
        ...state,
        ...groupAppointmentByTeamName(
          state.initialData,
          payload.filter,
          payload.customerSearch,
          false
        ),
        filters: payload,
        loading: false,
      };
    case progressTableTypes.GET_IP_APPOINTMENT_TYPES_DATA:
      return {
        ...state,
        montageAppointmentId:
          payload.response && payload.response.length === 1
            ? payload.response[0].id
            : '',
      };
    case progressTableTypes.GET_ALL_APPOINTMENT_TYPES_DATA:
      return {
        ...state,
        appointmentTypes: payload.response,
      };
    case progressTableTypes.GET_APPOINTMENT_WORKORDER_STATUS_LIST:
      let { appointmentStatusList, workOrderStatusList } = payload;
      return {
        ...state,
        appointmentStatusList: appointmentStatusList.map(
          (status: any) => status.masterLabel
        ),
        workOrderStatusList: workOrderStatusList.map(
          (status: any) => status.masterLabel
        ),
      };

    case progressTableTypes.GET_PROGRESS_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const progressTeamsReducer = (
  state = initialTeamsState,
  action: AnyAction
) => {
  const { type, payload } = action;

  switch (type) {
    case progressTeamsTypes.GET_PROGRESS_TEAMS_DATA:
      let { response } = payload;
      const teamGroups = groupBy(response, 'location');
      let teamLeads: any = {};
      let branchTLGroups: any = {};
      Object.keys(teamGroups).forEach((branch: string) => {
        let branchTeams = teamGroups[branch];
        branchTeams.forEach((team: any) => {
          if (!teamLeads[team.assignedPlId]) {
            teamLeads[team.assignedPlId] = team.assignedPlName;
          }
        });
        branchTLGroups[branch] = groupBy(teamGroups[branch], 'assignedPlId');
      });
      return {
        ...state,
        appointmentsTeamsData: response,
        teamGroups,
        branchTLGroups,
        teamLeads,
        error: null,
        loading: false,
      };
    case progressTeamsTypes.SET_PROGRESS_TEAMS_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};
