import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Spin,
  Layout,
  Button,
  Image,
  Row,
  Col,
  Space,
  Typography,
  Tag,
  Dropdown,
  Popover,
  Collapse,
  List,
  Avatar,
  Modal,
  Alert,
  Badge,
  Steps,
} from 'antd';
import cName from 'classnames';
import { RootState } from '../../store';
import AppointmentDetailsAction from '../formFieldAnswer/actions';
import WorkTypeAction from './actions';
import ApprovalPackageActions from '../approvalPackages/actions';
import ContentWorkType from './contentWorkType';
import {
  AnswersDataState,
  IAdditionalInfo,
  IQueue,
} from '../../constants/types';
import { approvalPackages, workTypeAssignment } from '../../router/routes';
import translation from '../../i18n/translation';
import styles from './formInstance.module.scss';
import ImageSelector from '../../components/imageSelector';
import { useIntl } from 'react-intl';
import { showNotificationMessage } from '../../utils/notification';
import AppLayout from '../../components/appLayout';
import FieldDetailsSidebar from './fieldDetailsSidebar';
import {
  CalendarOutlined,
  DownOutlined,
  FileImageTwoTone,
  FileTextOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  PhoneOutlined,
  RightOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import useAutoFetchData from '../../utils/useAutoFetchData';
import AccessControl from '../../components/accessControl';
import { IFeatures } from '../../store/rolesAndPrivileges';
import BreadcrumNavigation from '../../components/breadcrumNavigation';
import UserApprovalPackagesDrawer from '../approvalPackages/userApprovalPackagesDrawer';
import { FirstReviewStepSvg } from '../../components/icons/firstReviewStepSvg';
import { PhotoSvg } from '../../components/icons/photoSvg';
import { ChecksSvg } from '../../components/icons/checksSvg';
import DOMPurify from 'dompurify';
import { config } from '../../config';

const { Content } = Layout;
const { Text, Paragraph, Link } = Typography;
const { Panel } = Collapse;
const { Step } = Steps;

enum CancelReviewActions {
  CANCEL_REVIEWING = 'cancel_reviewing',
  SKIP_TO_NEXT = 'skip_to_next',
}

const FormInstance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params: {
    page?: string;
    appointId?: string;
    fieldId?: string;
    assignmentId?: string;
  } = useParams();
  const appointmentsDetails = useSelector(
    (state: RootState) => state.appointmentsDetails
  );
  const workTypeState = useSelector((state: RootState) => state.workType);
  const approvalPackageState = useSelector(
    (state: RootState) => state.approvalPackage
  );
  const intl = useIntl();

  const [
    additionalInfoImageVisible,
    setAdditionalInfoImageVisible,
  ] = useState<any>(false);

  const [personalReviewCount, setPersonalReviewCount] = useState<number>(0);
  const [showSteps, setShowSteps] = useState<boolean>(false);
  const [numberOfSteps, setNumberOfSteps] = useState<2 | 3>(3);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isPackageCompleted, setIsPackageCompleted] = useState<boolean>(false);
  const [cancelReviewModal, setCancelReviewModal] = useState<string | boolean>(
    false
  );

  const [
    canDisplayPackageCompletedModalOnce,
    setCanDisplayPackageCompletedModalOnce,
  ] = useState<boolean>(false);

  const [approvalPackagesDrawerOpen, setApprovalPackagesDrawerOpen] = useState(
    false
  );

  useEffect(() => {
    dispatch(AppointmentDetailsAction.setDefaultStateAnswersData());
    return () => {
      if (params.assignmentId) {
        dispatch(AppointmentDetailsAction.setDefaultStateUserData());
        dispatch(AppointmentDetailsAction.setDefaultStateAnswersData());
        dispatch(AppointmentDetailsAction.setDefaultPagination());
        dispatch(ApprovalPackageActions.setDefaultValues());
      }
      dispatch(WorkTypeAction.setDefaultValues());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(WorkTypeAction.setLoadingDetails(true));
    dispatch(
      AppointmentDetailsAction.getAppointmentsDetailsData(params.appointId!)
    );
  }, [params.appointId, dispatch]);

  useEffect(() => {
    if (params.assignmentId) {
      setCanDisplayPackageCompletedModalOnce(true);
      dispatch(WorkTypeAction.getAssignmentData(params.assignmentId));
      dispatch(ApprovalPackageActions.setLoading(true));
      dispatch(ApprovalPackageActions.getAssignedApprovalPackageInfo());
    }
  }, [params.assignmentId, dispatch]);

  useEffect(() => {
    if (params.fieldId) {
      dispatch(WorkTypeAction.getWorkTypeData(params.fieldId));
    }
  }, [params.fieldId, dispatch, isPackageCompleted]);

  useAutoFetchData(() => {
    if (params.fieldId && !isImageSelectorVisible && currentStep !== 2) {
      dispatch(WorkTypeAction.getWorkTypeData(params.fieldId));
    }
  });

  useEffect(() => {
    if (
      !approvalPackageState.isUnassigned &&
      params.assignmentId &&
      approvalPackageState.assignment &&
      approvalPackageState.assignment?.assignmentId &&
      approvalPackageState.assignment.assignmentId !== params.assignmentId
    ) {
      navigate(
        `${workTypeAssignment.pathWithParams(
          approvalPackageState.assignment.appointmentId,
          approvalPackageState.assignment.assignmentId
        )}`
      );
      if (params.assignmentId) {
        dispatch(AppointmentDetailsAction.setDefaultStateUserData());
        dispatch(AppointmentDetailsAction.setDefaultStateAnswersData());
        dispatch(AppointmentDetailsAction.setDefaultPagination());
      }
      dispatch(WorkTypeAction.setDefaultValues());

      if (params.appointId === approvalPackageState.assignment.appointmentId) {
        dispatch(
          AppointmentDetailsAction.getAppointmentsDetailsData(params.appointId!)
        );
      }
    } else if (
      appointmentsDetails.userData?.id === params.appointId &&
      (workTypeState.workTypeData?.formInstance?.formRevisionId ||
        workTypeState.assignment.formInstance?.formRevisionId)
    ) {
      dispatch(
        AppointmentDetailsAction.getAnswersData({
          appointmentId: params.appointId,
          formRevisionId:
            workTypeState.workTypeData?.formInstance?.formRevisionId ||
            workTypeState.assignment.formInstance?.formRevisionId,
          approvalRequired:
            !params.assignmentId &&
            localStorage.getItem('approval_required') === 'true',
          pageNumber: 1,
          pageSize: 200,
        })
      );
      dispatch(
        AppointmentDetailsAction.getMetadataInfos(
          appointmentsDetails.userData?.caseId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.appointId,
    params.assignmentId,
    appointmentsDetails.worktypes,
    appointmentsDetails.userData,
    workTypeState.workTypeData,
    workTypeState.assignment,
    approvalPackageState.assignment,
    approvalPackageState.isUnassigned,
  ]);

  useEffect(() => {
    if (approvalPackageState.packageInfo?.userStatistics?.length) {
      let personalCount = 0;
      approvalPackageState.packageInfo.userStatistics.forEach((item: any) => {
        personalCount = personalCount + (item.completed ?? 0);
      });
      setPersonalReviewCount(personalCount);
    }
  }, [approvalPackageState.packageInfo]);

  useEffect(() => {
    if (
      !approvalPackageState.isUnassigned &&
      appointmentsDetails.answersData &&
      appointmentsDetails.answersData.length > 0 &&
      workTypeState.assignment?.id
    ) {
      let index = getNextApprovalFieldIndex();
      if (!params.fieldId) {
        navigate(
          `${workTypeAssignment.pathWithParams(
            params.appointId!,
            params.assignmentId ?? '',
            appointmentsDetails.answersData[index > 0 ? index : 0].id
          )}`,
          { replace: true }
        );
      } else {
        setIsPackageCompleted(index < 0 && canDisplayPackageCompletedModalOnce);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.fieldId,
    params.appointId,
    params.assignmentId,
    approvalPackageState.isUnassigned,
    appointmentsDetails.answersData,
    workTypeState.assignment,
  ]);

  useEffect(() => {
    if (params.assignmentId) {
      if (hasUploadImages() && getGalleryImages()?.length > 1) {
        if (
          workTypeState.currentFormField?.formField?.approvalType === 'Default'
        ) {
          //4eyes principal: if the approval is in 1st queue (don't show steps)
          if (workTypeState?.assignment?.queue?.includes('First')) {
            setShowSteps(false);
            setCurrentStep(1);
            setNumberOfSteps(3);
          } else {
            setShowSteps(true);
          }
          //4eyes principal: if rejected on the 1st round (don't show image selection step)
          if (
            workTypeState.workTypeData?.status === 'Rejected' ||
            workTypeState?.workTypeData?.answers[0]?.status === 'Rejected'
          ) {
            setNumberOfSteps(2);
          } else {
            setNumberOfSteps(3);
          }
        } else {
          //2eyes principal: if 1st queue (show steps)
          workTypeState?.assignment?.queue?.includes('First')
            ? setShowSteps(true)
            : //2eyes principal: if the field was reopened on 2nd queue (show steps)
            workTypeState?.workTypeData?.answers[0]?.status === 'InReview' ||
              workTypeState?.workTypeData?.answers[0]?.status === 'Resubmitted'
            ? setShowSteps(true)
            : currentStep === 2 //if we are on the 2 step, this was a reopened field on the 2nd queue:
            ? setShowSteps(true)
            : setShowSteps(false);

          //if the last answer status as follows - goes to 1 step
          if (
            workTypeState?.workTypeData?.answers[0]?.status === 'InReview' ||
            workTypeState?.workTypeData?.answers[0]?.status === 'Resubmitted'
          )
            setCurrentStep(1);
        }
      } else {
        setShowSteps(false);
        setNumberOfSteps(3);
        setCurrentStep(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.assignmentId,
    workTypeState.currentFormField?.formField,
    workTypeState.workTypeData?.status,
    workTypeState.workTypeData?.answers,
    workTypeState?.assignment?.queue,
  ]);

  useEffect(() => {
    if (appointmentsDetails.error) {
      showNotificationMessage({
        message: intl.formatMessage({
          id:
            appointmentsDetails.error.errorKey ??
            'error_fetch_apointment_details',
        }),
        error: appointmentsDetails.error,
        onClose: () =>
          dispatch(AppointmentDetailsAction.resetAppointmentsDetailError()),
      });
    } else if (workTypeState.error) {
      showNotificationMessage({
        message: intl.formatMessage({
          id: workTypeState.error.errorKey ?? 'error_fetch_answer_details',
        }),
        error: appointmentsDetails.error || workTypeState.error,
        onClose: () => dispatch(WorkTypeAction.resetWorktypeDetailError()),
      });

      if (workTypeState.errorSubmit && params.fieldId) {
        dispatch(WorkTypeAction.getWorkTypeData(params.fieldId));
      }
    } else if (approvalPackageState.error) {
      showNotificationMessage({
        message: intl.formatMessage({
          id:
            approvalPackageState.error.errorKey ??
            'error_fetch_approval_package',
        }),
        error: approvalPackageState.error,
        onClose: () => dispatch(ApprovalPackageActions.resetError()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appointmentsDetails.error,
    workTypeState.error,
    workTypeState.errorSubmit,
    approvalPackageState.error,
  ]);

  useEffect(() => {
    if (
      approvalPackageState.isUnassigned &&
      params.fieldId &&
      params.assignmentId
    ) {
      dispatch(ApprovalPackageActions.setDefaultValues());
      navigate(approvalPackages.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalPackageState.isUnassigned]);

  const getNextApprovalFieldIndex = (): number =>
    appointmentsDetails.answersData.findIndex((answer: AnswersDataState) =>
      [IQueue.First, IQueue.FirstPriority].includes(
        workTypeState.assignment?.queue
      )
        ? ['Submitted', 'Resubmitted'].includes(answer.status)
        : ['InReview', 'Resubmitted'].includes(answer.status)
    );

  const formRevisions =
    appointmentsDetails &&
    appointmentsDetails.formRevisions &&
    workTypeState &&
    workTypeState.workTypeData &&
    workTypeState.workTypeData.formInstance
      ? {
          ...appointmentsDetails.formRevisions[
            workTypeState.workTypeData.formInstance.formRevisionId
          ],
        }
      : null;
  const [isImageSelectorVisible, setImageSelectorVisible] = useState(false);

  const hasUploadImages = (): boolean => {
    return (
      workTypeState?.currentFormField?.formField?.type !== 'Signature' &&
      (workTypeState.workTypeData?.answers
        ?.filter((e: { uploads: Array<string> }) => e.uploads?.length > 0)
        .map((e: { uploads: Array<string> }) => e.uploads).length ||
        false)
    );
  };

  const getGalleryImages = () => {
    let uploads = workTypeState.workTypeData?.answers.map(
      (e: { uploads: Array<string> }) => e.uploads
    );
    let mergedUploads = [].concat.apply([], uploads);
    let uniqueUploads = Array.from(new Set(mergedUploads));
    return uniqueUploads;
  };

  const startNextQueueReviews = () => {
    if (params.assignmentId && params.fieldId) {
      dispatch(ApprovalPackageActions.setLoading(true));
      dispatch(
        ApprovalPackageActions.assignNextApprovalPackage(
          params.assignmentId,
          params.fieldId
        )
      );
      setCancelReviewModal(false);
    }
  };

  const cancelReviews = (goToApprovalPackagePage: boolean = true) => {
    if (params.assignmentId && params.fieldId) {
      dispatch(
        ApprovalPackageActions.unassignApprovalPackage(
          params.assignmentId,
          params.fieldId,
          goToApprovalPackagePage
        )
      );
      setCancelReviewModal(false);
    }
  };

  const renderStringPlanImages = (urlStr: string, i: number) => {
    let urls = urlStr.split(';');
    return (
      <div onClick={() => setAdditionalInfoImageVisible(i)}>
        <Avatar.Group maxCount={4} size={64} style={{ cursor: 'pointer' }}>
          {urls.map((url: string, index: number) => (
            <Avatar shape="square" key={`${urlStr}-i-${index}`} src={url} />
          ))}
        </Avatar.Group>

        {additionalInfoImageVisible === i && (
          <div style={{ display: 'none' }}>
            <Image.PreviewGroup
              preview={{
                visible: !!additionalInfoImageVisible,
                onVisibleChange: (value) =>
                  setAdditionalInfoImageVisible(value),
              }}
            >
              {urls.map((url: string, index: number) => (
                <Image
                  key={`sringplan-image-${index}`}
                  width={96}
                  src={url}
                  style={{ padding: '0 4px' }}
                />
              ))}
            </Image.PreviewGroup>
          </div>
        )}
      </div>
    );
  };

  const renderAdditionalInfoRow = (item: IAdditionalInfo, i: number) => {
    const containsImages = [
      'WT_Strinplan',
      'WT_FotoTypenschildWR',
      'WT_DCTiefenerderHauserdung',
      'WT_EMGDCTiefenerderHauserdung',
      'WT_ACMTiefenerderHauserdung',
      'WT_PotischieneUKErdung',
      'WT_ACMKabelPotischiene',
    ].includes(item.key);
    return (
      <div
        className={cName({
          [styles.additionalInfoDataContent]: !containsImages,
        })}
      >
        <h4 className={styles.additionalInfoTitle}>{item?.title}</h4>
        <div className={styles.additionalInfoDescription}>
          {containsImages ? (
            renderStringPlanImages(item.value, i)
          ) : (
            <Text type="secondary">{item.value}</Text>
          )}
        </div>
      </div>
    );
  };

  const onOkPackageCompletedModal = () => {
    setIsPackageCompleted(false);
    setCanDisplayPackageCompletedModalOnce(false);
    startNextQueueReviews();
  };

  const onToggleDrawer = () => {
    setApprovalPackagesDrawerOpen(!approvalPackagesDrawerOpen);
  };

  const handleGoToNextStep = (num: number | undefined) => {
    if (!num) {
      let step = currentStep < 3 ? currentStep + 1 : 3;
      setCurrentStep(step);
    } else {
      setCurrentStep(num);
    }
  };

  const worktypeName = workTypeState.currentFormField.workType;

  const timesRejected: number = workTypeState.workTypeData.answers
    ? workTypeState.workTypeData.answers.filter(
        (answer: any) => answer.status === 'Rejected'
      ).length
    : 0;

  const buildabitilyLinkObj = {
    key: `mtv-protokol-link`,
    icon: <FileTextOutlined />,
    label: (
      <Link
        download
        target="_blank"
        rel="noreferrer"
        href={`${config.buildabilityUrl}/${appointmentsDetails.userData?.externalCaseId}`}
      >
        MVT Protokoll - {appointmentsDetails.userData?.customer?.name}
      </Link>
    ),
  };
  return (
    <AppLayout>
      <Layout className={styles.contentLayout}>
        <FieldDetailsSidebar
          fieldsList={appointmentsDetails.answersData}
          formRevisions={appointmentsDetails.formRevisions}
          worktypeName={worktypeName}
        />
        <Content className={styles.dataLayout}>
          <Content className={styles.groupContent}>
            <Row>
              <Col flex="auto">
                <Space>
                  <BreadcrumNavigation
                    page={params.page!}
                    appointmentId={params.appointId!}
                    customerName={appointmentsDetails.userData?.customer?.name}
                    worktypeName={worktypeName}
                  />
                </Space>
              </Col>
              {hasUploadImages() &&
                workTypeState.workTypeData.status === 'Approved' &&
                appointmentsDetails.userData?.status !== 'Closed' && (
                  <Col flex="136px" style={{ paddingTop: '4px' }}>
                    <AccessControl
                      featureKey={IFeatures.fieldDetailUpdateImage}
                    >
                      <Button
                        className={styles.editImagesButton}
                        size="small"
                        icon={<FileImageTwoTone />}
                        onClick={() => setImageSelectorVisible(true)}
                      >
                        <Text className={styles.editImagesButton}>
                          {translation('update_images')}
                        </Text>
                      </Button>
                    </AccessControl>
                    <ImageSelector
                      appointmentId={params.appointId}
                      formFieldId={workTypeState.workTypeData.formFieldId}
                      formInstanceId={
                        workTypeState.workTypeData.formInstance.id
                      }
                      isVisible={isImageSelectorVisible}
                      externalCaseId={
                        appointmentsDetails.userData?.externalCaseId
                      }
                      onClose={(isSubmitted) => {
                        setImageSelectorVisible(false);
                        if (isSubmitted)
                          dispatch(
                            WorkTypeAction.getFreshAnswerData(
                              workTypeState.workTypeData.id,
                              ''
                            )
                          );
                      }}
                      images={getGalleryImages()}
                    />
                  </Col>
                )}
            </Row>
            {workTypeState.currentFormField.formField?.label && [
              <Row align="middle" key="1stRow">
                <Col flex="auto">
                  <div>
                    <Title className={styles.headerContent} level={4}>
                      {workTypeState.currentFormField.formField?.label}
                    </Title>
                  </div>
                </Col>

                <Col flex="153px" className={styles.dropdownCol}>
                  <Dropdown
                    placement="bottomRight"
                    menu={{
                      items:
                        appointmentsDetails?.userData?.files &&
                        appointmentsDetails?.userData?.files.length > 0
                          ? [
                              ...appointmentsDetails?.userData?.files.map(
                                (f: any, index: number) => ({
                                  key: `appointmentfilesList-${index}`,
                                  icon: <FileTextOutlined />,
                                  label: (
                                    <Link
                                      download
                                      target="_blank"
                                      rel="noreferrer"
                                      href={f.url}
                                    >
                                      {f.fileName}
                                    </Link>
                                  ),
                                })
                              ),
                            ]
                          : [],
                    }}
                  >
                    <Button size="small">
                      <Text type="secondary">
                        {translation('montage_documents')}
                      </Text>{' '}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>,
              <Row key="2ndRow">
                <Space style={{ flexWrap: 'wrap' }}>
                  <Popover
                    content={
                      <div>
                        <Text strong>
                          {appointmentsDetails.userData?.customer?.name}
                        </Text>
                        <Paragraph>
                          <Text type="secondary">
                            {
                              appointmentsDetails.userData?.customerAddress
                                ?.address1
                            }
                          </Text>
                        </Paragraph>
                        {appointmentsDetails.userData?.customerAddress
                          ?.telephone && (
                          <Paragraph>
                            <Space>
                              <PhoneOutlined />
                              <Text type="secondary">
                                {
                                  appointmentsDetails.userData?.customerAddress
                                    ?.telephone
                                }
                              </Text>
                            </Space>
                          </Paragraph>
                        )}
                        {appointmentsDetails.userData?.externalCaseId && (
                          <Text>
                            <Space>
                              <Text strong>{translation('case_id')}:</Text>
                              <Text type="secondary">
                                {appointmentsDetails.userData?.externalCaseId}
                              </Text>
                            </Space>
                          </Text>
                        )}
                        {appointmentsDetails.userData?.appointmentDate && (
                          <Paragraph>
                            <Space>
                              <CalendarOutlined />
                              <Text type="secondary">
                                {intl.formatDate(
                                  appointmentsDetails.userData?.appointmentDate
                                )}
                              </Text>
                            </Space>
                          </Paragraph>
                        )}
                      </div>
                    }
                    title={translation('appointment_details')}
                    trigger="hover"
                  >
                    <InfoCircleOutlined />
                  </Popover>
                  <Text type="secondary" strong>
                    {appointmentsDetails.userData?.customer?.name}
                  </Text>
                  <Text type="secondary">
                    ({appointmentsDetails.userData?.externalCaseId})
                  </Text>
                  {appointmentsDetails.userData?.team?.name && (
                    <Tag style={{ maxWidth: '165px' }}>
                      <Text
                        ellipsis
                        title={appointmentsDetails.userData?.team?.name}
                      >
                        {appointmentsDetails.userData?.team?.name}
                      </Text>
                    </Tag>
                  )}
                  {appointmentsDetails.userData?.team?.assignedPlName && (
                    <Tag style={{ maxWidth: '165px' }}>
                      <Text
                        ellipsis
                        title={
                          appointmentsDetails.userData?.team?.assignedPlName
                        }
                      >
                        {appointmentsDetails.userData?.team?.assignedPlName}
                      </Text>
                    </Tag>
                  )}
                  {appointmentsDetails.userData?.team?.location && (
                    <Tag style={{ maxWidth: '165px' }}>
                      <Text
                        ellipsis
                        title={appointmentsDetails.userData?.team?.location}
                      >
                        {appointmentsDetails.userData?.team?.location}
                      </Text>
                    </Tag>
                  )}
                </Space>
              </Row>,
            ]}
          </Content>
          {showSteps && (
            <Content className={styles.stepsContent}>
              <Steps type="default" responsive={true}>
                <Step
                  icon={<FirstReviewStepSvg />}
                  title={translation('review')}
                  status={currentStep > 1 ? 'finish' : 'process'}
                />
                {numberOfSteps === 3 && (
                  <Step
                    icon={<PhotoSvg />}
                    title={translation('update_images')}
                    status={
                      currentStep < 2
                        ? 'wait'
                        : currentStep === 2
                        ? 'process'
                        : 'finish'
                    }
                  />
                )}
                <Step
                  icon={<ChecksSvg />}
                  title={translation('review_completed')}
                  status={currentStep < numberOfSteps ? 'wait' : 'finish'}
                />
              </Steps>
            </Content>
          )}
          <Content
            className={
              showSteps ? styles.dataContentInReview : styles.dataContent
            }
          >
            {timesRejected &&
            (workTypeState.workTypeData.status !== 'Rejected' ||
              timesRejected > 1) ? (
              <Alert
                message={translation('multiple_rejection', { timesRejected })}
                type="error"
                showIcon
              />
            ) : null}
            <div className={styles.tableContent}>
              <Spin
                spinning={workTypeState.loading || approvalPackageState.loading}
              >
                <ContentWorkType
                  workTypeData={workTypeState.workTypeData}
                  currentFormField={workTypeState.currentFormField}
                  fieldId={params.fieldId ?? ''}
                  appointmentId={params.appointId!}
                  page={params.page!}
                  selectedCheckbox={
                    workTypeState.currentFormField.formField?.answers
                  }
                  userData={appointmentsDetails.userData}
                  formRevisions={formRevisions}
                  reasonCodes={workTypeState.reasonCodes}
                  showSteps={showSteps}
                  handleGoToNextStep={handleGoToNextStep}
                  images={
                    workTypeState.workTypeData?.answers?.length
                      ? getGalleryImages()
                      : []
                  }
                  currentStep={currentStep}
                  numberOfSteps={numberOfSteps}
                  setNumberOfSteps={setNumberOfSteps}
                />
              </Spin>
            </div>
          </Content>
        </Content>
        <Content className={styles.infoContent}>
          {params.assignmentId && (
            <div className={styles.packageBox}>
              <Row
                align="middle"
                justify="space-between"
                className={styles.reviewedPackagesContainer}
              >
                <Col>
                  <Text style={{ color: '#fff' }}>
                    {translation('reviewed_packages')}:
                  </Text>
                  <Badge
                    count={personalReviewCount}
                    style={{
                      marginLeft: '6px',
                      background: 'var(--background-4)',
                      color: 'black',
                      boxShadow: 'none',
                    }}
                    overflowCount={999}
                    showZero
                  />
                </Col>
                <Col>
                  <Button
                    type="text"
                    style={{ color: '#fff' }}
                    onClick={onToggleDrawer}
                  >
                    <LeftOutlined /> {translation('open_review_history')}
                  </Button>
                </Col>
              </Row>
              <div className={styles.nextPackageBox}>
                <div
                  style={{
                    paddingTop: '16px',
                    color: workTypeState?.assignment?.queue?.includes('First')
                      ? 'var(--color-black)'
                      : 'var( --color-gray-5)',
                  }}
                >
                  <Text
                    style={{
                      color: 'currentcolor',
                    }}
                  >
                    {translation('first_queue')}:
                  </Text>
                  <Text
                    style={{
                      margin: '0 0 0 8px',
                      color: 'currentcolor',
                      fontWeight: 'bold',
                    }}
                  >
                    {(approvalPackageState.packageInfo.firstCount ?? 0) +
                      (approvalPackageState.packageInfo.firstPriorityCount ??
                        0)}
                  </Text>
                </div>
                <div
                  style={{
                    paddingTop: '16px',
                    color: workTypeState?.assignment?.queue?.includes('Second')
                      ? 'var(--color-black)'
                      : 'var( --color-gray-5)',
                  }}
                >
                  <Text
                    style={{
                      color: 'currentcolor',
                    }}
                  >
                    {translation('second_queue')}:
                  </Text>
                  <Text
                    style={{
                      margin: '0 0 0 8px',
                      color: 'currentcolor',
                      fontWeight: 'bold',
                    }}
                  >
                    {(approvalPackageState.packageInfo.secondCount ?? 0) +
                      (approvalPackageState.packageInfo.secondPriorityCount ??
                        0)}
                  </Text>
                </div>
              </div>
              <div
                className={styles.nextPackageBox}
                style={{ paddingBottom: '16px' }}
              >
                <Button
                  className="buttonLink cancelLinkBtn"
                  type="default"
                  style={{ fontSize: '14px' }}
                  onClick={() =>
                    isPackageCompleted
                      ? cancelReviews()
                      : setCancelReviewModal(
                          CancelReviewActions.CANCEL_REVIEWING
                        )
                  }
                  disabled={!!approvalPackageState.loading}
                >
                  {translation('cancel_reviewing')}
                </Button>
                <Button
                  type="primary"
                  icon={<RightOutlined />}
                  onClick={() =>
                    isPackageCompleted
                      ? startNextQueueReviews()
                      : setCancelReviewModal(CancelReviewActions.SKIP_TO_NEXT)
                  }
                  loading={!!approvalPackageState.loading}
                >
                  <span>{translation('assign_next_package')}</span>
                </Button>
              </div>
            </div>
          )}

          <Collapse
            defaultActiveKey={['acceptance_criteria', 'additional_infos']}
            expandIconPosition="end"
            className={styles.infoCollapse}
            style={{ maxHeight: !params.assignmentId ? '100%' : '' }}
            ghost
          >
            {(workTypeState.currentFormField.formField?.attachments ||
              workTypeState.currentFormField.formField?.content) && (
              <Panel
                header={
                  <Text strong>{translation('acceptance_criteria')}</Text>
                }
                key="acceptance_criteria"
                className={styles.acceptancePanel}
              >
                <div className={styles.acceptanceContent}>
                  {workTypeState.currentFormField.formField?.content && (
                    <div
                      className={
                        workTypeState.currentFormField.formField?.attachments
                          ? styles.acceptanceContentText
                          : ''
                      }
                    >
                      <Text type="secondary">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              workTypeState.currentFormField.formField?.content
                            ),
                          }}
                        ></div>
                      </Text>
                    </div>
                  )}
                  {workTypeState.currentFormField.formField?.attachments && (
                    <div className={styles.acceptanceImageContent}>
                      <Image.PreviewGroup>
                        {workTypeState.currentFormField.formField?.attachments.map(
                          (item: any, index: number) => (
                            <Image
                              key={`attachment-image-${index}`}
                              src={item.uri}
                              style={{ maxHeight: '30vh', maxWidth: '400px' }}
                            />
                          )
                        )}
                      </Image.PreviewGroup>
                    </div>
                  )}
                </div>
              </Panel>
            )}
            {appointmentsDetails.additionalInfos &&
              appointmentsDetails.additionalInfos.length > 0 && (
                <Panel
                  header={<Text strong>{translation('additional_infos')}</Text>}
                  key="additional_infos"
                  className={styles.additionalInfoPanel}
                >
                  <div className={styles.additionalInfoContent}>
                    <List
                      dataSource={appointmentsDetails.additionalInfos}
                      size="small"
                      renderItem={(item: IAdditionalInfo, i: number) => {
                        return item?.title ? (
                          <List.Item key={`inlineListContent-${item.key}`}>
                            {renderAdditionalInfoRow(item, i + 1)}
                          </List.Item>
                        ) : null;
                      }}
                    />
                  </div>
                </Panel>
              )}
          </Collapse>
        </Content>
        <Modal
          title={translation('package_completed_title')}
          okText={translation('assign_next_package')}
          onOk={onOkPackageCompletedModal}
          onCancel={() => {
            setIsPackageCompleted(false);
            setCanDisplayPackageCompletedModalOnce(false);
            cancelReviews(false);
          }}
          open={
            showSteps
              ? currentStep === numberOfSteps &&
                isPackageCompleted &&
                canDisplayPackageCompletedModalOnce
              : isPackageCompleted && canDisplayPackageCompletedModalOnce
          }
          cancelButtonProps={{ style: { display: 'none' } }}
          footer={[
            hasUploadImages() &&
              workTypeState.workTypeData.status === 'Approved' &&
              appointmentsDetails.userData?.status !== 'Closed' && (
                <AccessControl featureKey={IFeatures.fieldDetailUpdateImage}>
                  <Button
                    key="update_image_modal_btn"
                    icon={<FileImageTwoTone />}
                    onClick={() => setImageSelectorVisible(true)}
                  >
                    <Text className={styles.editImagesButton}>
                      {translation('update_images')}
                    </Text>
                  </Button>
                </AccessControl>
              ),
            <Button
              key="upackage_completed_modal_ok_btn"
              type="primary"
              onClick={onOkPackageCompletedModal}
            >
              {translation('assign_next_package')}
            </Button>,
          ]}
        >
          <p>{translation('package_completed_message')}</p>
        </Modal>

        <Modal
          title={translation('warning')}
          okText={translation('skip_review')}
          onOk={() => {
            cancelReviewModal === CancelReviewActions.SKIP_TO_NEXT
              ? startNextQueueReviews()
              : cancelReviews();
          }}
          onCancel={() => {
            setCancelReviewModal(false);
          }}
          open={!!cancelReviewModal}
          cancelText={translation('keep_reviewing')}
        >
          <p>{translation('on_cancel_package_review')}</p>
        </Modal>

        <UserApprovalPackagesDrawer
          open={approvalPackagesDrawerOpen}
          onClose={onToggleDrawer}
        />
      </Layout>
    </AppLayout>
  );
};

export default FormInstance;
