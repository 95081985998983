import { generateDocumentFields } from '../../utils/generateDocumentFields';
import { Buffer } from 'buffer';

const docx = require('docx');
const sizeOf = require('buffer-image-size');
const dateFormat = require('dateformat');
const Promise = require('bluebird');

const {
  Paragraph,
  Table,
  TableCell,
  TableRow,
  convertInchesToTwip,
  TextRun,
  AlignmentType,
  BorderStyle,
  WidthType,
  ShadingType,
  ImageRun,
  TableLayoutType,
  VerticalAlign,
} = docx;

const enpalAddress = {
  title: 'Enpal B.V',
  addressLine1: 'Koppenstraße 8',
  city: '10243 Berlin',
};

const getHeaderOfDocument = async (appointment: any, metadata: any) => {
  const response = await fetch(
    'https://craftosstorage.blob.core.windows.net/enpal-logo/5ea1497819897597ff26526c_enpal_vector.png'
  );
  const arrayBuffer = await response.arrayBuffer();
  const image = Buffer.from(new Uint8Array(arrayBuffer));
  const { documentTitle } = generateDocumentFields(
    appointment?.appointmentType?.name
  );

  return [
    new Table({
      borders: {
        top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
      },
      rows: [
        new TableRow({
          borders: {
            top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
          },
          children: [
            new TableCell({
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
              },
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: image,
                      transformation: {
                        width: 150,
                        height: 50,
                      },
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
              },
              alignment: AlignmentType.RIGHT,
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun(enpalAddress.title)],
                }),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun(enpalAddress.addressLine1)],
                }),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun(enpalAddress.city)],
                }),
              ],
            }),
          ],
        }),
      ],
    }),
    new Paragraph({
      text: '',
    }),
    new Paragraph({
      alignment: AlignmentType.CENTER,

      children: [
        new TextRun({
          text: documentTitle,
          bold: true,
          size: 32,
        }),
      ],
    }),

    new Table({
      borders: {
        top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Table({
                  borders: {
                    top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                    bottom: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: 'FFFFFF',
                    },
                    left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                    right: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: 'FFFFFF',
                    },
                  },
                  rows: [
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Einsatzart',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.appointmentType.name,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Geplant für',
                                }),
                              ],
                            }),
                          ],
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: dateFormat.default(
                                    appointment.appointmentDate,
                                    'dd.mm.yyyy HH:MM'
                                  ),
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Abgeschlossen von',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.team?.name,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Beschreibung',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.appointmentType.description,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Anlagetyp',
                                }),
                              ],
                            }),
                          ],
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: getMetadataValue(
                                    metadata,
                                    'WT_Anlagetyp'
                                  ),
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Anzahl der Module',
                                }),
                              ],
                            }),
                          ],
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: getMetadataValue(
                                    metadata,
                                    'WT_NumberOfModules'
                                  ),
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Hersteller & Typ',
                                }),
                              ],
                            }),
                          ],
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: getMetadataValue(
                                    metadata,
                                    'WT_Hersteller'
                                  ),
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'PV Nennleistung (kWp)',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: getMetadataValue(
                                    metadata,
                                    'WT_PvNennleistung'
                                  ),
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                  ],
                }),
              ],
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
              },
              margin: {
                top: convertInchesToTwip(2),
                bottom: convertInchesToTwip(2),
              },
            }),
            new TableCell({
              children: [
                new Table({
                  borders: {
                    top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                    bottom: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: 'FFFFFF',
                    },
                    left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                    right: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: 'FFFFFF',
                    },
                  },
                  rows: [
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Kunde',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.customer?.name,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      margin: {
                        top: convertInchesToTwip(2),
                        bottom: convertInchesToTwip(2),
                      },
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Erster Vertragspartner',
                                }),
                              ],
                            }),
                          ],
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.customer?.name,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Adresse',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.customerAddress.address1,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Ort (PLZ)',
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${appointment.customerAddress.city}(${appointment.customerAddress.zipCode})`,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          margin: {
                            top: convertInchesToTwip(2),
                            bottom: convertInchesToTwip(2),
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: 'Telefonnummer',
                                }),
                              ],
                            }),
                          ],
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                        new TableCell({
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          margins: {
                            top: 80,
                            bottom: 80,
                            left: 100,
                            right: 100,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: appointment.customerAddress.telephone,
                                  bold: true,
                                }),
                              ],
                            }),
                          ],
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            left: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                            right: {
                              style: BorderStyle.NONE,
                              size: 0,
                              color: 'FFFFFF',
                            },
                          },
                        }),
                      ],
                    }),
                  ],
                }),
              ],
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
              },
            }),
          ],
          borders: {
            top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
          },
        }),
      ],
    }),
    new Paragraph(''),
  ];
};

const generateAnswer = async (answer: any, answers: any) => {
  try {
    let lastAnswer = [new Paragraph('')];
    if (answer) {
      if (answer?.uploads && answer?.uploads.length) {
        lastAnswer = [];

        // Concurrently fetch all images for this answer.
        // Could be further improved by prefetching all images for all answers and keep them in memory.
        const images = await Promise.map(
          answer.uploads,
          async (url: string) => {
            const response = await fetch(url);
            const arrayBuffer = await response.arrayBuffer();
            const buffer = Buffer.from(new Uint8Array(arrayBuffer));
            const size = sizeOf(buffer);
            const maxWidth = 300;
            const ratio = maxWidth / size.width;
            const height = size.height * ratio;
            const width = size.width * ratio;

            return {
              url,
              width,
              height,
              buffer,
            };
          }
        );

        for (let i = 0; i < answer.uploads.length; i++) {
          const image = images.find(
            (img: any) => img.url === answer.uploads[i]
          );

          lastAnswer.push(
            new Paragraph({
              children: [
                new ImageRun({
                  data: image?.buffer,
                  transformation: {
                    width: image.width,
                    height: image.height,
                  },
                }),
              ],
            })
          );
        }
      } else if (answer?.content && answer?.content.length) {
        lastAnswer = [new Paragraph(answer.content)];
      } else if (answer?.selections && answer?.selections.length) {
        lastAnswer = [
          new Paragraph(
            answer.selections
              .map((s: any) => answers.find((a: any) => a.id === s)?.label)
              .join(', ')
          ),
        ];
      } else if (answer?.dateContent && answer?.dateContent.length) {
        lastAnswer = [
          new Paragraph(
            dateFormat.default(answer?.dateContent, 'dd.mm.yyyy HH:MM')
          ),
        ];
      }
    }
    return lastAnswer;
  } catch (e) {
    return [new Paragraph('')];
  }
};

const generateRow = async (data: any, answers: any, metadata: any) => {
  if (data.dependsOn) {
    let valid = false;
    data.dependsOn.forEach((condition: any) => {
      const answer = answers.filter(
        (a: any) => a.formFieldId === condition.fieldId
      );
      if (answer && answer[0]) {
        const snapshot = answer[0].answers[0];
        if (snapshot.selections.includes(condition.answerId)) valid = true;
      }
    });
    if (!valid) return;
  }

  if (data.metadata && data.metadata.dependOn) {
    let valid = true;
    const conditions = data.metadata.dependOn;
    Object.keys(conditions).forEach((key) => {
      const value = getMetadataValue(metadata, key);
      if (value !== conditions[key]) {
        valid = false;
      }
    });
    if (!valid) return;
  }

  let fieldAnswer: any[] = [];
  let legalText: any[] = [];
  if (data.legalText) {
    legalText = [
      new TableRow({
        children: [
          new TableCell({
            columnSpan: 2,
            shading: {
              fill: 'd4d4d4',
              type: ShadingType.REVERSE_DIAGONAL_STRIPE,
              color: 'auto',
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: data.legalText,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ];
  }
  if (data.answer) {
    fieldAnswer = await generateAnswer(data.answer, data.answers);
    if (fieldAnswer.length > 1) {
      const rows = await generateImagesRows(fieldAnswer, data);
      rows.unshift(...legalText);
      return rows;
    }
  }
  return [
    ...legalText,
    new TableRow({
      children: [
        new TableCell({
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          shading: {
            fill: 'f0f0f0',
            type: ShadingType.REVERSE_DIAGONAL_STRIPE,
            color: 'auto',
          },
          margins: {
            top: 200,
            bottom: 200,
            left: 100,
            right: 100,
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: getTextLabel(data),
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          children: fieldAnswer,
          shading: {
            fill: 'ffffff',
            type: ShadingType.REVERSE_DIAGONAL_STRIPE,
            color: 'auto',
          },
          margins: {
            top: 200,
            bottom: 200,
            left: 100,
            right: 100,
          },
          width: {
            size: 60,
            type: WidthType.PERCENTAGE,
          },
        }),
      ],
    }),
  ];
};

const generateTableInRow = async (
  data: any,
  headerData: any,
  answers: any,
  metadata: any
) => {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].children) {
      const row: any = await generateTableInRow(
        data[i].children,
        headerData,
        answers,
        metadata
      );
      row && rows.push(...row);
    } else {
      const row = await generateRow(data[i], answers, metadata);
      row && rows.push(...row);
    }
  }

  if (!rows.length) {
    return;
  }

  const tableHeader = [
    new TableRow({
      tableHeader: true,
      children: [
        new TableCell({
          shading: {
            fill: '909090',
            type: ShadingType.REVERSE_DIAGONAL_STRIPE,
            color: 'auto',
          },
          verticalAlign: VerticalAlign.CENTER,
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: getTextLabel(headerData),
                  size: 22,
                  bold: true,
                }),
              ],
            }),
          ],
          columnSpan: 2,
        }),
      ],
    }),
  ];

  return [
    new TableRow({
      children: [
        new TableCell({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },

          margins: {
            top: 200,
            bottom: 200,
            left: 100,
            right: 100,
          },
          shading: {
            fill: 'f0f0f0',
            type: ShadingType.REVERSE_DIAGONAL_STRIPE,
            color: 'auto',
          },
          children: [
            new Paragraph(''),
            new Table({
              layout: TableLayoutType.FIXED,
              width: {
                size: 98,
                type: WidthType.PERCENTAGE,
              },
              rows: [...tableHeader, ...rows],
            }),
          ],
          columnSpan: 2,
        }),
      ],
    }),
  ];
};

const getTextLabel = (data: any) =>
  data.additionalFieldData ? data.additionalFieldData : data.label;

const generateImagesRows = async (data: any, field: any) => {
  const rows: any[] = [];
  data.forEach((el: any, idx: any) =>
    rows.push(
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 40,
              type: WidthType.PERCENTAGE,
            },
            shading: {
              fill: 'f0f0f0',
              type: ShadingType.REVERSE_DIAGONAL_STRIPE,
              color: 'auto',
            },
            margins: {
              top: 200,
              bottom: 200,
              left: 100,
              right: 100,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Foto: ' + (idx + 1) + ' ' + getTextLabel(field),
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            children: [el],
            shading: {
              fill: 'ffffff',
              type: ShadingType.REVERSE_DIAGONAL_STRIPE,
              color: 'auto',
            },
            margins: {
              top: 200,
              bottom: 200,
              left: 100,
              right: 100,
            },
            width: {
              size: 60,
              type: WidthType.PERCENTAGE,
            },
          }),
        ],
      })
    )
  );

  return rows;
};

const generateWorkTypeField = async (
  field: any,
  answers: any,
  metadata: any
) => {
  const rows = [];

  if (!field.children) {
    const row = await generateRow(field, answers, metadata);
    row && rows.push(...row);
  } else if (field.children) {
    const row = await generateTableInRow(
      field.children,
      field,
      answers,
      metadata
    );
    row && rows.push(...row);
  }

  return rows;
};

const getMetadataValue = (metadata: any, key: string) => {
  if (metadata) {
    const obj = metadata.filter((a: any) => a.key === key);

    if (obj && obj[0]) return obj[0].value;
  }
  return '';
};
export { getHeaderOfDocument, generateWorkTypeField };
